export default {
  'en': {
    'main.discover': 'Discover',
    'main.read': 'Read',
    'main.subscribe':'Subscribe',
    'main.subscribe_to_newsletter': 'Subscribe to the newsletter',
    'main.buy_the_books': 'Buy books',
    'main.follow_me_instagram': 'Follow Vitaly on Instagram',
    'main.404_error':'404 error',
    'main.go_back_to_hp':'Go back to homepage',
    'main.read_my_bio':'Read my bio',
    'main.close':'Fermer',
    'main.newsletter.notice':'We take care of your data, it is easy to unsubscribe.',
    'main.receive.news.for.free':'Get a free preview of our new publications',
    'main.also.read':'Read also',

    'footer.join_community':'Join thousands of freethinkers',
    'footer.receive_articles':'Get early and free access to our new publications',
    'footer.share_your_thoughts':'Share your thoughts and comments with us on Facebook !',
    'footer.subscrive_to_vitaly_page':'Subscribe to Vitaly\'s page',
    'footer.subscrive_to_vitaly_newsletter':'Subscribe to Vitaly\'s newsletter',
    'footer.subscrive_to_vitaly_newsletter_success':'Thank you for your registration. You can download the book in the following formats:',
    'footer.your_email':'Your email',
    'footer.privacy.policy.link':'Privacy policy',

    'cookie.banner':'We use cookies to ensure you get the best experience on our website.',
    'cookie.learn_more':'Learn more',
    'cookie.got_it':'Got it !',

    'post.the':'The posts',
    'post.last':'Latest articles',
    'post.read':'Read more',
    'post.all':'All the posts',

    'book.the':'The books',
    'book.last':'Last books',
    'book.all':'All books',

    'project.all':'All projects',
    'project.last':'Last projects',
    'commitment.the':'The commitments',
    'commitment.all':'All commitments',
    'commitment.last':'Last commitments',

    'buy.book.on.amazon': 'Buy on Amazon',
    'book.get.for.free': 'Get the book for free',
    'book.newsletter.notice': 'You will also receive Vitaly Malkin upcoming publications. Your information is safe and you will never have to pay.',
    'book.newsletter.success.notice': 'Thank you for your registration. You can download the book in the following formats:',

    'lp.exclusive':'exclusive',
    'lp.limitied.offer.until':'Limited offer until',
    'lp.security.notice':'Your information is safe, you will never have to pay.',
    'lp.an.exceptional':'An exceptional author',
    'lp.be.the.first':'Be the first to receive Vitaly\'s next books',
    'lp.five.good.reasons':'{count} good reasons to read "{title}"',

    'lp.email.label':'Enter your email to receive the book for free:'
  },
  'fr': {
    'main.discover': 'Découvrir',
    'main.read': 'Lire',
    'main.subscribe':'S\'abonner',
    'main.subscribe_to_newsletter': 'Abonnez-vous à la newsletter',
    'main.buy_the_books': 'Achetez les livres',
    'main.follow_me_instagram': 'Suivez Vitaly sur Instagram',
    'main.404_error':'Erreur 404',
    'main.go_back_to_hp':'Retournez à la page d\'accueil',
    'main.read_my_bio':'Lisez ma biographie',
    'main.close': 'Fermer',
    'main.newsletter.notice':'Nous prenons soin de vos données, le désabonnement est facile.',
    'main.receive.news.for.free':'Recevez gratuitement, en avant-première, nos nouvelles publications',
    'main.also.read':'À lire aussi',

    'footer.join_community':'Rejoignez des milliers de libres penseurs',
    'footer.receive_articles':'Recevez gratuitement, en avant-première, nos nouvelles publications',
    'footer.share_your_thoughts':'Partagez vos réflexions et commentaires avec nous sur Facebook !',
    'footer.subscrive_to_vitaly_page':'Abonnez-vous à la page de Vitaly',
    'footer.subscrive_to_vitaly_newsletter':'Abonnez-vous à la newsletter',
    'footer.subscrive_to_vitaly_newsletter_success':'Merci de votre inscription. Vous pouvez télécharger le livre dans les formats suivants:',
    'footer.your_email':'Votre email',
    'footer.privacy.policy.link':'Mentions légales',

    'cookie.banner':'Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site.',
    'cookie.learn_more':'En savoir plus',
    'cookie.got_it':'J\'ai compris !',

    'book.the':'Les livres',
    'book.last':'Derniers livres',
    'book.all':'Tous les livres',

    'post.the':'Les articles',
    'post.last':'Derniers articles',
    'post.read':'Lire',
    'post.all':'Tous les articles',

    'project.all':'Tous les projets',
    'project.last':'Derniers projets',

    'commitment.the':'Les engagements',
    'commitment.all':'Tous les engagements',
    'commitment.last':'Derniers engagements',

    'buy.book.on.amazon': 'Acheter sur Amazon',
    'book.get.for.free': 'Recevez le livre gratuitement',
    'book.newsletter.notice': 'Vous recevrez également les prochaines publications de Vitaly Malkin en avant-première. Vos informations sont en sécurité et vous n’aurez jamais à payer.',
    'book.newsletter.success.notice': 'Merci de votre inscription. Vous pouvez télécharger le livre dans les formats suivants:',

    'lp.exclusive':'exclusif',
    'lp.limitied.offer.until':'Offre limitée jusqu\'au',
    'lp.security.notice':'Vos informations sont en sécurité, vous n\'aurez jamais à payer.',
    'lp.an.exceptional':'Un auteur hors normes',
    'lp.be.the.first':'Recevez les prochains livres en avant-première',
    'lp.five.good.reasons':'{count} bonnes raisons de lire "{title}"',

    'lp.email.label':'Saisissez votre email pour recevoir le livre gratuitement :'
  },
  'de': {
    'main.discover': 'Entdecken',
    'main.read': 'Lesen Sie',
    'main.subscribe':'Abonnieren',

    'main.subscribe_to_newsletter': 'Den Newsletter abonnieren',
    'main.buy_the_books': 'Die Bücher kaufen',
    'main.follow_me_instagram': 'Vitaly auf Instagram folgen',
    'main.404_error':'Error 404',
    'main.go_back_to_hp':'Zurück zur Startseite',
    'main.read_my_bio':'Meine Biographie lesen',
    'main.close': 'schließen',
    'main.newsletter.notice':'Wir kümmern uns um Ihre Daten, das Abbestellen ist einfach.',
    'main.receive.news.for.free':'Erhalten Sie eine kostenlose Vorschau auf unsere neuen Publikationen',
    'main.also.read':'Siehe auch',

    'footer.join_community':'Werden Sie einer von Tausenden Freidenkern',
    'footer.receive_articles':'Erhalten Sie eine kostenlose Vorschau auf unsere neuesten Publikationen!',
    'footer.share_your_thoughts':'Teilen Sie uns Ihre Gedanken und Kommentare auf Facebook mit !',
    'footer.subscrive_to_vitaly_page':'Vitaly auf Facebook folgen',
    'footer.subscrive_to_vitaly_newsletter':'Den Newsletter abonnieren',
    'footer.subscrive_to_vitaly_newsletter_success':'Vielen Dank für Ihre Anmeldung. Sie können das Buch in den folgenden Formaten herunterladen:',
    'footer.your_email':'Ihre E-Mail-Adresse',
    'footer.privacy.policy.link':'Rechtliche Hinweise',

    'cookie.banner':'Wir verwenden Cookies, um sicherzustellen, dass Sie die bestmögliche Erfahrung auf unserer Website machen können.',
    'cookie.learn_more':'Mehr über uns',
    'cookie.got_it':'Ich hab\'s!',

    'book.last':'Neueste Bücher',
    'book.all':'Alle Bücher',
    'book.the':'Bücher',

    'post.the':'Artikel',
    'post.last':'Neueste Artikel',
    'post.read':'Lesen',
    'post.all':'Alle Artikel',

    'project.all':'Jedes Projekt',
    'project.last':'Neueste Projekte',

    'commitment.the':'Zusagen',
    'commitment.all':'Alle Verpflichtungen',
    'commitment.last':'letzte Verpflichtungen',

    'book.get.for.free': 'Erhalten Sie das Buch kostenlos',

    'buy.book.on.amazon': 'Auf Amazon bestellen',

    'book.newsletter.notice': 'Außerdem erhalten Sie Vorschauen auf kommende Publikationen von Vitaly Malkin. Ihre Daten sind sicher und Sie müssen nie bezahlen.',
    'book.newsletter.success.notice': 'Vielen Dank für Ihre Anmeldung. Sie können das Buch in den folgenden Formaten herunterladen:',

    'lp.exclusive':'exklusiv',
    'lp.limitied.offer.until':'Dieses Angebot gilt bis zum ',
    'lp.security.notice':'Ihre Angaben sind geschützt, Sie werden nicht bezahlen müssen',
    'lp.an.exceptional':'Ein außergewöhnlicher Autor',
    'lp.be.the.first':'Nutzen Sie die Gelegenheit, Neuerscheinungen bereits vorab zu entdecken',

    'lp.five.good.reasons':'{count} gute Gründe, "{title}" zu lesen',
    'lp.email.label':'Geben Sie Ihre E-Mail-Adresse ein, um das Buch kostenlos zu erhalten :'
  },
  'es': {
    'main.discover': 'Descubra',
    'main.read': 'Lea',
    'main.subscribe':'SUSCRIBIRSE',
    'main.subscribe_to_newsletter': 'Suscríbase a mi boletín de noticias',
    'main.buy_the_books': 'Para comprar un libro',
    'main.follow_me_instagram': 'Suscribirse a la página de Vitaly Instagram',
    'main.404_error':'404 error',
    'main.go_back_to_hp':'Página de inicio',
    'main.read_my_bio':'Lee mi biografia',
    'main.close':'Cerca',
    'main.newsletter.notice':'CUIDAMOS SUS DATOS, DARSE DE BAJA ES FÁCIL.',
    'main.receive.news.for.free':'Obtenga un avance gratuito de nuestras nuevas publicaciones',
    'main.also.read':'Leer también',

    'footer.join_community':'Únase a miles de librepensadores',
    'footer.receive_articles':'Obtenga una vista previa gratuita de nuestras nuevas publicaciones',
    'footer.share_your_thoughts':'Comparta con nosotros sus opiniones y comentarios en Facebook.',
    'footer.subscrive_to_vitaly_page':'Suscribirse a la página de Vitaly',
    'footer.subscrive_to_vitaly_newsletter':'Suscríbase a mi boletín de noticias',
    'footer.subscrive_to_vitaly_newsletter_success':'Gracias por su inscripción. Puede descargar el libro en los siguientes formatos:',
    'footer.your_email':'Tu email',
    'footer.privacy.policy.link':'Política de privacidad',

    'cookie.banner':'Este sitio web utiliza cookies para garantizar que usted tenga la mejor experiencia en nuestro sitio web.',
    'cookie.learn_more':'Leer más',
    'cookie.got_it':'Lo entiendo. !',

    'post.the':'ARTÍCULOS',
    'post.last':'Últimos artículos',
    'post.read':'Leer',
    'post.all':'Publicar todo',

    'book.the':'Los libros',
    'book.last':'Últimos libros',
    'book.all':'Todos los libros',

    'project.all':'Proyectos',
    'project.last':'Últimos proyectos',
    'commitment.the':'Los compromisos',
    'commitment.all':'Todos los compromisos',
    'commitment.last':'Últimos compromisos',

    'buy.book.on.amazon': 'Comprar en Amazon',
    'book.get.for.free': 'Obtén el libro gratis',
    'book.newsletter.notice': 'También recibirá avances de las próximas publicaciones de Vitaly Malkin. Sus datos están seguros y nunca tendrá que pagar.',
    'book.newsletter.success.notice': 'Gracias por su inscripción. Puede descargar el libro en los siguientes formatos:',

    'lp.exclusive':'Exclusivo',
    'lp.limitied.offer.until':'Oferta limitada hasta',
    'lp.security.notice':'Su  información está protegida y nunca tendrá que pagar por nada',
    'lp.an.exceptional':'Un autor exceptional',
    'lp.be.the.first':'Sea el primero en recibir los próximos libros de Vitaly',
    'lp.five.good.reasons':'{count} buenas razones para leer "{title}"',

    'lp.email.label':'Introduzca su correo electrónico para obtener un libro gratis:'
  },
  'ru': {
    'main.discover': 'ОТКРЫТЬ',
    'main.read': 'Читать',
    'main.subscribe':'Подписаться',
    'main.subscribe_to_newsletter': 'Подпишись на новостную рассылку',
    'main.buy_the_books': 'Купить книгу',
    'main.follow_me_instagram': 'Подпишись на страницу Виталия в Instagram',
    'main.404_error':'404 ошибка',
    'main.go_back_to_hp':'На домашнюю страницу',
    'main.read_my_bio':'О Виталии Малкине',
    'main.close':'Закрыть',
    'main.newsletter.notice':'МЫ ЗАБОТИМСЯ О СОХРАННОСТИ ВАШИХ ДАННЫХ, ОТМЕНИТЬ ПОДПИСКУ ОЧЕНЬ ПРОСТО.',
    'main.receive.news.for.free':'Получайте бесплатно все новые публикации',
    'main.also.read':'Читайте также',

    'footer.join_community':'Стань одним из тысяч вольнодумцев',
    'footer.receive_articles':'Получай наши публикации бесплатно!',
    'footer.share_your_thoughts':'Делись своими идеями на Фейсбуке',
    'footer.subscrive_to_vitaly_page':'Подпишись на страницу Виталия!',
    'footer.subscrive_to_vitaly_newsletter':'Подпишись на новостную рассылку',
    'footer.subscrive_to_vitaly_newsletter_success':'Благодарим вас за регистрацию. Вы можете скачать книгу в следующих форматах:',
    'footer.your_email':'Ваш имейл',
    'footer.privacy.policy.link':'Политика конфиденциальности',

    'cookie.banner':'Наш сайт использует файлы сookies, чтобы гарантировать максимальное удобство пользователям, предоставляя персонализированную информацию.',
    'cookie.learn_more':'Узнать больше',
    'cookie.got_it':'Понял !',

    'post.the':'СТАТЬИ',
    'post.last':'Последние статьи',
    'post.read':'Читать далее',
    'post.all':'Все посты',

    'book.the':'КНИГИ',
    'book.last':'Последние книги',
    'book.all':'Все книги',

    'project.all':'Проекты',
    'project.last':'Последний проект',
    'commitment.the':'Благотворительность',
    'commitment.all':'Все проекты по благотворительности',
    'commitment.last':'Последние проекты по благотворительности',

    'buy.book.on.amazon': 'КУПИТЬ НА AMAZON',
    'book.get.for.free': 'Получите книгу бесплатно',
    'book.newsletter.notice': 'Вы также будете получать новости о публикациях Виталия Малкина. Ваша информация в безопасности, и вам никогда не придется платить.',
    'book.newsletter.success.notice': 'Благодарим вас за регистрацию. Вы можете скачать книгу в следующих форматах:',

    'lp.exclusive':'Эксклюзивное предложение',
    'lp.limitied.offer.until':'До',
    'lp.security.notice':'Мы заботимся о конфиденциальности ваших данных, Вам никогда не придется платить.',
    'lp.an.exceptional':'Уникальный автор',
    'lp.be.the.first':'Получите следующие книги Виталия первыми',
    'lp.five.good.reasons':'{count} главных причин, почему вы должны прочитать "{title}"',

    'lp.email.label':'Введите свой  адрес email, чтобы получить книгу в подарок:'
  },
  'pt': {
    'main.discover': 'Descobrir',
    'main.read': 'Ler',
    'main.subscribe': 'Subscrever',
    'main.subscribe_to_newsletter': 'Subscrever a newsletter',
    'main.buy_the_books': 'Comprar livros',
    'main.follow_me_instagram': 'Seguir a Vitaly no Instagram',
    'main.404_error':'404 error',
    'main.go_back_to_hp':'Voltar a página inicial',
    'main.read_my_bio':'Ler minha bio',
    'main.close':'Fechar',
    'main.newsletter.notice':'Cuidamos dos seus dados, é fácil desinscrever.',
    'main.receive.news.for.free':'Receba uma amostra grátis das nossas novas publicações',
    'main.also.read':'Ler também',

    'footer.join_community':'Junte-se a milhares de pensadores livres',
    'footer.receive_articles':'Receba acesso grátis e antecipado às nossas novas publicações',
    'footer.share_your_thoughts':'Compartilhe seus pensamentos e comentários conosco no Facebook !',
    'footer.subscrive_to_vitaly_page':'Se inscreva na página da Vitaly',
    'footer.subscrive_to_vitaly_newsletter':'Se inscreva na newsletter da Vitaly',
    'footer.subscrive_to_vitaly_newsletter_success':'Obrigado pelo seu registro. Você pode baixar o livro nos seguintes formatos:',
    'footer.your_email':'Seu e-mail',
    'footer.privacy.policy.link':'Política de privacidade',

    'cookie.banner':'Usamos cookies para garantir que você tenha a melhor experiência no nosso site.',
    'cookie.learn_more':'Saiba mais',
    'cookie.got_it':'Entendi!',

    'post.the':'Os posts',
    'post.last':'Últimos artigos',
    'post.read':'Leia mais',
    'post.all':'Todos os posts',

    'book.the':'Os livros',
    'book.last':'Últimos livros',
    'book.all':'Todos os livros',

    'project.all':'Todos os projetos',
    'project.last':'Últimos projetos',
    'commitment.the':'Os compromissos',
    'commitment.all':'Todos os compromissos',
    'commitment.last':'Últimos compromissos',

    'buy.book.on.amazon': 'Comprar na Amazon',
    'book.get.for.free': 'Receba o livro de graça',
    'book.newsletter.notice': 'Você também receberá as próximas publicações da Vitaly Malkin. Suas informações estão seguras e você nunca terá que pagar.',
    'book.newsletter.success.notice': 'Obrigado pelo seu registro. Você pode baixar o livro nos seguintes formatos:',

    'lp.exclusive':'exclusive',
    'lp.limitied.offer.until':'Oferta limitada até',
    'lp.security.notice':'Sua informação está segura, você nunca terá que pagar.',
    'lp.an.exceptional':'Um autor excepcional',
    'lp.be.the.first':'Seja o primeiro a receber os próximos livros da Vitaly',
    'lp.five.good.reasons':'{count} boas razões para ler "{title}"',
    'lp.email.label':'Digite seu e-mail para receber o livro de graça:'
}

};